.toggle-switch {
	position: relative;
	width: 75px;
	display: inline-block;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	text-align: left;
}

.toggle-switch-checkbox {
	display: none;
}

.toggle-switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #bbb;
	border-radius: 6px;
	margin: 0!important;
	position: relative;
}


.toggle-switch-label:focus {
	outline: none;
}

.toggle-switch-label:focus > span {
	box-shadow: 0 0 2px 5px red;
}

.toggle-switch-label > span:focus {
	outline: none;
}

.toggle-switch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before, .toggle-switch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 38px;
	padding: 0;
	line-height: 38px;
	font-size: 14px;
	color: white;
	font-weight: bold;
	box-sizing: border-box;
}

.toggle-switch-inner:before {
	content: attr(data-yes);
	padding-left: 10px;
	background-color: #2F855A;
	color: #fff;
}

.toggle-switch-disabled {
	background-color: #ddd;
	cursor: not-allowed;
}

.toggle-switch-disabled:before {
	background-color: #ddd;
	cursor: not-allowed;
}

.toggle-switch-inner:after {
	content: attr(data-no);
	padding-right: 10px;
	background-color: #979797;
	color: #fff;
	text-align: right;
}

.toggle-switch-switch {
	display: block;
	width: 28px;
	margin: 4px 5px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 38px;
	border: 0 solid #979797;
	border-radius: 6px;
	transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
	right: 0px;
}

.toggle-switch.small-switch {
	width: 40px;
}

.toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
	content: "";
	height: 20px;
	line-height: 20px;
}

.toggle-switch.small-switch .toggle-switch-switch {
	width: 16px;
	right: 20px;
	margin: 2px;
}

@media screen and (max-width: 991px) {
	.toggle-switch {
		transform: scale(0.9);
	}
}

@media screen and (max-width: 767px) {
	.toggle-switch {
		transform: scale(0.825);
	}
}

@media screen and (max-width: 575px) {
	.toggle-switch {
		transform: scale(0.75);
	}
}