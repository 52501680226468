@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');  
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');  
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');  
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Bold.ttf') format('truetype');  
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'OpenSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
  font-family: 'OpenSans';
}

body {
  padding-right: 0!important;
  overflow: auto!important;
}
.Content {
  overflow: hidden;
  padding: 60px 0;
}

.Container {
  padding: 0 60px;
}
.dropdown-menu {
  padding: 20px 0 40px;
}
.drop-menu svg{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  top: calc(50% - 10px);
}
.dropdown-menu a {
  display: flex;
  align-items: center;
  color: #42525a;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
}
.dropdown-menu li {
  padding-left: 30px;
  padding-right: 30px;
}
.dropdown-menu svg {
  margin-right: 14px;
}
h1.pageTitle {
  margin-top: 0;
  margin-bottom: 12px;

  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  color: #000000;
}

.pageText {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  padding-bottom: 50px;  
  border-bottom: 1px solid;
  border-color: rgba(217, 223, 233, 0.4);
}

.pageToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 40px 16px;
  border-bottom: 1px solid;
  border-color: rgba(217, 223, 233, 0.4);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}
div.MuiAccordionSummary-root {
  padding: 0;
}

.MuiAccordion-root {
  overflow: hidden;
}
.MuiAccordion-root:before {
  display: none;
}
.red-border {
  /*border: solid 1px red;*/
}
div.MuiAccordionSummary-content {
  margin: 20px 0;
}
.profile-modal input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  font-weight: 500;
  font-family: 'Poppins';
  box-sizing: border-box;
}
.profile-modal label {
  margin-bottom: 15px;
  font-weight: 500;
}
.profile-modal input {
  border-width: 1px;
}
.profile-modal select {
  min-width: 50%;
  font-weight: 500;
  font-family: 'Poppins';
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  box-sizing: border-box;
}
.profile-modal .color-div-wrap label {
  padding-left: 32px;
}
.profile-modal .color-div-wrap {
  padding: 0 12px;
  margin-bottom: 20px;
}
.profile-modal .color-div-wrap .color-div {    
  width: 50px;
  margin-right: 27px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;  
  border: solid 0.5px #979797;
  margin-bottom: 5px;
}
.profile-modal .color-div-wrap .color-input input{
  width: calc(100% - 27px);    
}
div.MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.profile-modal input[value=""],
.profile-modal select.red-border.before_save{
  border: none;
  background-color: rgba(0, 0, 0, 0.04);
}
.logo_uploader_wrap {
  margin: 0 50px;
}
.logo_uploader {
  position: relative;
  width: 188px;
  border: solid 1px rgba(217, 223, 233, 0.4);
  height: 207px;
}
.logo_uploader:not(.red-border) {
  border: solid 1px rgba(217, 223, 233, 0.4);
}
.logo_uploader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 2;
  background: white;
  padding: 40px;
  box-sizing: border-box;
}
.logo_uploader_wrap a {
  color: black;
  display: block;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 15px;
}
.logo_uploader .not-selected-div {
  z-index: 1;
  display: flex;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}
.logo_uploader .not-selected-div span{
  color: rgba(0, 0, 0, 0.56);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.logo_uploader input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 3;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.main-menu {
  color: #42525a;
  font-size: 14px;
  font-weight: 600;
}
.error-message {
  color: red;
  font-weight: 500;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
}
tr.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #F1F7FE;
}
.filter-box {
  display: flex;
  justify-content: flex-end;
}
.filter-item {
  display: inline-flex;
  margin-left: 70px;
  cursor: pointer;
  position: relative;
}
.filter-modal {
  border-radius: 4px;
  box-shadow: 0 -7px 25px -4px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: absolute;
  top: 60px;
  width: 385px;
  right: 0;
  cursor: initial;
  max-width: calc(100vw - 40px);
  padding: 40px;
  z-index: 10;
}
.filter-modal:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  right: 50px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #fff;
}
.filter-modal button {
  border-radius: 28px;  
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #42525a;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-top: 40px;
}
.filter-modal p.title {
  font-size: 18px;
  font-weight: 600;
  padding-left: 25px;
  margin: 0;
  padding-right: 25px;
  margin-bottom: 25px;
}
.checkbox-wrap {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.checkbox-wrap label {
  font-size: 18px;
  color: #333;
}
.checkbox-wrap input {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.filter-box {
  margin-bottom: 50px;
}
.photo_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.select__multi-value__remove {
  background-color: transparent!important;
  color: black!important;
  cursor: pointer;
}
.photo_wrap img {
  border-radius: 50%;
  margin-right: 32px;
  width: 120px;
  height: auto;
}
.campaign-form-wrap textarea {
  height: 260px;
  width: 100%;
  border-radius: 6px;
  border: solid 1px rgba(66, 82, 90, 0.24);
  padding: 13px 24px;
  font-size: 16px;
  margin-bottom: 15px;
  font-family: 'OpenSans';
}
.campaign-form-wrap textarea.notes {
  height: 100px;
}
.campaign-form-wrap.settings-form-wrap button {
  width: 200px;
  margin-top: 40px;
}
.delivery .order-scheduled {
  font-size: 12px;
  color: rgba(66, 82, 90, 0.64);
  text-transform: uppercase;
  margin-top: 16px;
  margin-bottom: 8px;
}
.delivery .order-meals {
  margin-top: 10px;
  margin-bottom: 0;
  color: #333;
}
.delivery .comment {
  margin: 0;
  font-size: 12px;
  margin-bottom: 35px;
  color: #333;
}
.campaign-form-wrap select {
  width: 100%;
  box-sizing: border-box;
  height: 54px;
  margin-bottom: 15px;
  padding: 0 24px;
  font-size: 16px;  
  border-radius: 6px;
  border: solid 1px rgba(66, 82, 90, 0.24);
}
.basic-multi-select {
  margin-bottom: 15px;
  text-transform: capitalize;
}
.basic-multi-select .select__control {
  height: 54px;
}
.settings-form-wrap .basic-multi-select .select__control input {
  height: auto;
}
.campaign-form-wrap .photo_wrap img {
  margin-right: 32px;
  width: 200px;
  object-fit: contain;
  height: 260px;
  border-radius: 6px;
}
.settings-form-wrap.campaign-form-wrap {
  max-width: 470px;
  margin-top: 56px;
  margin-bottom: 56px;
}
.campaign-form-wrap .photo_wrap .placeholder-img,
.settings-form-wrap .photo_wrap .placeholder-img {
  margin-right: 20px;
  width: 240px;
  height: 300px;
  padding: 20px;
  border-radius: 6px;
  background-color: rgba(216, 216, 216, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-form-wrap label.uploader_button {
  padding: 14px 32px;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  border: solid 1px rgba(66, 82, 90, 0.24);
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-bottom: 0;
}
.uploader_button input {
  margin-bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  display: none;
}
div.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.preview {
  position: relative;
  width: 100%;
  margin-top: 5px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  text-align: center;
}
.preview-content {
  width: 300px;
  position: relative;
  overflow: hidden;
  transform-origin: top left;

}
.preview img {
  width: 100%;
}
.tint-wrap {
  font-size: 0;
}
.preview .link {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}
.switch-icon {
  font-size: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 7px;
}
.switch-icon svg {
  transform: rotate(45deg);
}
p.error_message {
  color: red!important;
  margin-top: 10px!important;
  font-size: 0.8rem!important;
}
.signup_content {
  background-color: rgba(240, 240, 240, 0.4);
  max-width: 870px;
  margin: 0 auto;
  padding: 64px 20px 80px;
  border-radius: 6px;
}
@media (max-width: 767px) {
  .signup_content {
    padding: 43px 20px 70px;
  }
  .photo_wrap {
    display: block;
    text-align: center;
  }
  .campaign-form-wrap .photo_wrap .placeholder-img {
    margin: 0 auto 15px;
  }

  .share-actions {
    display: flex;
    flex-direction: column;
    align-items: center;  
  }
  .share-campaign-modal h1 {
    font-size: 24px;
  }
  .share-actions input {
    min-height: 44px;
    max-width: 100%;
  }
  .share-actions button {
    margin-left: 0!important;
    margin-top: 10px;
  }
}
.signup_wrap h1{
  font-size: 48px;
  /*font-family: OpenSans;*/
  color: #42525a;
  text-align: center;
  margin-bottom: 9px;
  font-weight: normal;
}
.signup_wrap p{
  font-size: 16px;
  color: #42525a;
  font-weight: normal;  
  margin: 0 0 56px;
  text-align: center;
  line-height: 1.25;
}
.signup_wrap .signup_content{
  margin: 0 auto;
}
.signup_wrap input,
.signup_wrap select {
  width: 100%;
  box-sizing: border-box;
  height: 54px;
  margin-bottom: 8px;
  padding: 0 27px;
  font-size: 16px;
  border: none;
}
.signup_wrap select:invalid {
  color: #757575;
}
.select_wrap {
  position: relative;
}
.select_wrap:after {
    content: '';
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    border: solid rgba(66, 82, 90, 0.64);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(
      45deg
    );
    -webkit-transform: rotate(
      45deg
    );
}
.select_wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.signup_wrap button {
  width: 200px;
  height: 48px;
  border: none;
  background: #fed202;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #42525a;
  margin: 40px calc(50% - 100px) 0;
  cursor: pointer;
}
.signup_wrap button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.double-input {
  display: flex;
  justify-content: space-between;
}
.signup_wrap p.buttons_title {
  margin-bottom: 24px;
  color: #333;  
  font-weight: 600;
}
.signup.Container {
  padding: 0;
}
.button_wrap label{
  color: #42525a;
  background: white;
  font-weight: bold;
  display: inline-block;
  padding: 20px 40px;
  line-height: 1.33;
  margin: 0 5px;
  font-size: 24px;
  border-radius: 36px;
  cursor: pointer;
}
.number {
  padding: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  width: 72px;
  height: 72px;
  min-width: 72px;
  justify-content: center;
  background: #42525a;
  color: white;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  margin: 0 18px;
}
.skip_step {
  cursor: pointer;
  font-size: 14px;
  color: #42525a;
  margin-left: 32px;
}
.buttons_wrap.weekdays .button_wrap label{
  padding: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  width: 72px;
  height: 72px;
  min-width: 72px;
  justify-content: center;
}
.button_wrap input[type="checkbox"]:checked+label {
  background: #42525a;
  color: white;
}
.double-input input{
  max-width: calc(50% - 4px);
}
.buttons_wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  align-items: center;
}
.buttons_wrap input {
  display: none;
}
span.number_button {
  width: 56px;
  font-family: 'Poppins';
  height: 56px;
  background: white;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #42525a;
  border-radius: 50%;
}
.signup_wrap p.meals_comment {
  font-size: 14px;
  font-weight: 600;  
  color: #42525a;
  margin: 28px auto 34px;  
  letter-spacing: -0.19px;
}
.settings-form-wrap {
  max-width: 680px;
  margin: 0 auto;
}
.settings-form-wrap label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
  color: #42525a;
}

.settings-form-wrap label.notation {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  display: block;
  color: #42525a;
}

.settings-form-wrap input {
  width: 100%;
  box-sizing: border-box;
  height: 54px;
  margin-bottom: 16px;
  padding: 0 24px;
  font-size: 16px;
  border: solid 1px rgba(66, 82, 90, 0.24);
  border-radius: 6px;
}
.settings-form-wrap button {
  color: #42525a;
  width: 114px;
  border: none;
  cursor: pointer;
  height: 48px;
  margin: 0;
  font-size: 14px;
  background: #fed202;
  font-weight: 600;
  border-radius: 6px;
  text-transform: initial;
}
.settings-form-wrap button:disabled {
  pointer-events: none;
  opacity: 0.4;
}
.notifications-wrap {
  margin-top: 8px;
  padding: 40px 0;
  border-top: solid 2px rgba(0, 0, 0, 0.04);
  border-bottom: solid 2px rgba(0, 0, 0, 0.04);
}
.notification_comment {
  margin: 0;
  font-size: 14px;
  color: rgba(66, 82, 90, 0.4);
  line-height: 22px;
  padding-right: 100px;
}
.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total_meals {
  width: 470px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  background: white;
  border-radius: 6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #42525a;
  font-size: 56px;
}
.form-content-wrap {
  max-width: 470px;
  width: 100%;
  margin: 0 auto;
}
.step_wrap {
  position: relative;
  max-width: 870px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
.step_wrap p {
  margin-bottom: 35px;
  color: rgba(66, 82, 90, 0.64);  
  font-size: 14px;
  font-weight: 600;
  padding-top: 7px;
}
.step_wrap span {
  margin-bottom: 35px;
  color: rgba(66, 82, 90, 0.64);  
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.notification .content {
  max-width: calc(100% - 100px);
}
.notification_comment a {
  color: rgba(66, 82, 90, 0.4);
}
.page-banner {
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  align-items: center;
  border-bottom: solid 2px #f0f0f0;
}

.page-banner.no-border {
  border-bottom: none;
}

.page-banner img.account-img {
  width: 100px;
  object-fit: cover;
  height: 100px;
  border-radius: 50%;
}

.page-banner .account-content {
  margin-left: 32px;
}
.page-banner .account-content h2{
  margin-bottom: 3px;
  font-size: 48px;
  color: #42525a;
  font-weight: normal;
  margin-top: 0;
  line-height: 1.25;
}
.page-banner .account-content p{
  margin: 0;
  font-size: 16px;
  line-height: 1.25;
  color: #42525a;
}
.page_title {
  padding-bottom: 60px;
  font-size: 48px;
  color: #42525a;
  text-align: center;
  border-bottom: solid 2px #f0f0f0;
  margin-bottom: 80px;
}
.overview-submenu {
  display: flex;
  justify-content: center;
  padding: 10px 30px;
  border-bottom: solid 2px #f0f0f0;
}
.overview-submenu button {
  width: 128px;  
  border-radius: 6px;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  justify-content: center;
  border: none;
  margin: 25px 5px;
  font-size: 18px;
  font-weight: 600;  
  color: rgba(66, 82, 90, 0.64);
}
.overview-submenu button.active {
  background-color: rgba(0, 0, 0, 0.04);
  color: #42525a;
}
.overview .page_title {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.forget_password {
  font-size: 14px;
  color: #42525a;
  margin-left: 32px;
}
.overview .campaigns-form {
  background-color: transparent;
  padding: 0;
  margin-bottom: 67px;
}
.overview {
  max-width: 888px;
  margin: 80px auto;
  padding: 0 30px;
}
.overview .campaigns-form .form-left img {
  width: 196px;
  height: 240px;
  object-fit: contain;
}
.campaign-status-wrap {
  display: flex;
  align-items: center;
}

.campaign-private-wrap .notification{
  max-width: 200px;
}

.campaign-private-wrap > p {
  font-family: OpenSans;
  font-size: 10px;
  color: #42525a;
  max-width: 550px;
}

.share-campaign, .our-mission-but {
  width: 207px;
  height: 48px;
  border-radius: 6px;
  background-color: #fed202;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #42525a;
  margin-top: 30px;
}
.overview-content.overview .share-campaign {
  margin-top: 0;
  margin-left: 50px;
  background-color: #1c976b;
  color: white;
}
.overview-content.overview .view-profile {
  width: 207px;
  height: 48px;
  border-radius: 6px;
  background-color: #fed202;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #42525a;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .overview-content.overview .view-profile {
    margin-top: 10px;
    margin-left: 0;
  }
}
.meals-detail {
  display: flex;
  width: 300px;
}
.meals-detail p {
  margin: 0;
}

.meals-detail svg {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.meals-detail svg * {
  fill: #d21311;
}
.meals-detail p.title {
  font-size: 28px;
  font-weight: 600;
  color: #42525a;
  line-height: 30px;
}
.meals-detail-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.meals-detail-wrap .campaign_info {
  margin-top: -25px;
  min-width: 400px;
}
.meals-detail p.content {
  color: #42525a;
  margin-top: 5px;
}
.meals-detail p.pause {
  font-size: 14px;
  color: rgba(66, 82, 90, 0.64);
  padding-bottom: 3px;
  border-bottom: solid 1px rgba(66, 82, 90, 0.64);
  clear: both;
  display: inline-block;
}
.overview-content hr {
  border: solid 1.5px rgba(0, 0, 0, 0.04);
  background: rgba(0, 0, 0, 0.04);
  margin: 56px 0;
}
.overview-content label {
  font-size: 18px;
  font-weight: 600;
  color: #42525a;
}
.overview-content label:not(.uploader_button) {
  margin-top: 25px;
}
.share-campaign svg {
  margin-right: 10px;
}
@media (max-width: 910px) {
  .step_wrap span {
    left: 20px;
  }
}
p.error_message:empty {
  display: none;
}
@media (max-width: 767px) {
  .campaigns-form .form-left {
    text-align: center;
  }
  .Container {
    padding: 0 10px;
  }
  .overview {
    margin-top: 0;
    padding: 0 20px;
  }
  .Content {
    padding: 30px 0;
  }
  .overview-submenu {
    padding: 10px 0;
  }
  .overview-submenu button {
    margin: 25px 0;
  }
  .page-banner {
    padding-left: 10px;
    padding-right: 10px;
  }
  .signup_wrap button {
    margin-top: 47px;
  }
  .page-banner .account-content h2 {
    font-size: 20px;
  }
  .page-banner img.account-img {
    width: 60px;
    height: 60px;
  }
  .page-banner .account-content {
    margin-left: 16px;
  }
  .account.Container {
    padding: 0 10px;
  }
  .page_title {
    font-size: 32px;
  }
  .notification .content {
    max-width: 100%;
  }
  .notification .toggle-switch {
    min-width: 80px;
  }
  .notification_comment {
    padding-right: 0;
  }
  .signup_wrap h1 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 4px;
  }
  .step_wrap span {
    top: -20px;
  }
  .signup_wrap p {
    font-size: 10px;
    margin-bottom: 37px;
  }
  .buttons_wrap {
    margin-bottom: 48px;
  }
  .signup_wrap p.buttons_title {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .button_wrap label {
    font-size: 12px;
    padding: 12px 32px;
  }
  .buttons_wrap.weekdays .button_wrap label, .number {
    width: 50px;
    height: 50px;
    min-width: 50px;
    margin-left: 12.5px;
    margin-right: 12.5px;
    font-size: 16px;
  }
  .buttons_wrap.weekdays {
    flex-wrap: wrap;
    margin: 0 auto 19px;
  }
  .signup_wrap .signup_content.step_3 p {
    max-width: 247px;
    margin-left: auto;
    margin-right: auto;
  }
  .signup_wrap .signup_content.step_4 p {
    max-width: 195px;
    margin-left: auto;
    margin-right: auto;
  }
  .buttons_wrap.weekdays .button_wrap {
    margin-bottom: 20px;
  }
  span.number_button {
    width: 20px;
    height: 20px;
    font-size: 16px;
  }
  .total_meals {
    width: 330px;
    font-size: 32px;
  }
  .signup_wrap p.meals_comment {
    font-size: 12px;
    width: 230px;
    margin-bottom: 7px;
  }
  .signup_wrap .step_wrap p{
    font-size: 14px;
  }
}



/* Dashboard */

.campaigns-wrap {
  max-width: 940px;
  margin: 0 auto;
}
.campaigns-title {
  font-size: 48px;
  margin-bottom: 32px;
  line-height: normal;  
  font-family: bely;
  color: #42525a;
}
.campaign-title {
  font-size: 48px;
  margin-bottom: 18px;
  margin-top: 0;
  line-height: normal;  
  font-family: bely;
  color: #42525a;
}
.campaign-description {
  font-size: 20px;
  line-height: 1.7;
  color: #42525a;
  margin-bottom: 34px;
  margin-top: 0;
}
.view-campaign .campaign-description {
  height: 170px;
  overflow: hidden;
  margin-bottom: 64px;
}
.campaign-description_wrap {
  position: relative;
}
.campaign-description_wrap .read_more {
  position: absolute;
  top: 170px;
  font-size: 20px;
  line-height: 1.7;
  text-decoration: underline;
  cursor: pointer;
  color: #42525a;
}
.campaigns-form {
  padding: 39px 53px 36px 56px;
  border-radius: 6px;
  background-color: rgba(240, 240, 240, 0.4);
  display: flex;
  position: relative;
  margin-bottom: 34px;
}
.campaigns-form .form-left {
  display: flex;
  flex-direction: column;
}
.select__value-container>div {
  background-color: white;
    font-size: 18px;
}
.view-campaign .campaign-description_wrap.active .campaign-description {
  height: auto;
  overflow: visible;
}

.view-campaign .campaign-description_wrap.active .read_more {
  display: none;
}
.select__value-container svg {
  width: 20px!important;
  height: 20px!important;
}
.campaigns-form .form-right {
  width: 100%;
  margin-top: 50px;
  margin-left: 50px;
}
.campaigns-form .form-right .campaign_title {
  font-size: 24px;
  font-weight: 600;
  color: #42525a;
  margin: 0;
  line-height: 1.3;
}
.campaigns-form .form-left img {
  width: 200px;
  height: 120px;
  border-radius: 6px;
}
.campaigns-form .form-left button {
  border-radius: 6px;
  background-color: #fed202;
  border: none;
  width: 200px;
  font-size: 14px;
  height: 40px;
  margin-top: 20px;
}
.campaigns-form .progress_bar {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  margin-top: 18px;
  max-width: 484px;
  overflow: hidden;
  position: relative;
  margin-bottom: 18px;
}
.campaign_info div {
  margin-top: 17px;
}
.campaigns-dropdown {
  position: absolute;
  right: 53px;
  border-radius: 6px;
  background: white;
  box-shadow: 0 8px 30px -8px rgb(66 82 90 / 24%);
  z-index: 100;  
  min-width: 40px;
  min-height: 40px;
}
.campaigns-dropdown ul {
  list-style: none;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0 60px;
  width: 270px;
  height: 189px;
  display: none;
}
.campaigns-dropdown ul li a {
  font-size: 14px;
  font-weight: 600;
  color: #42525a;
  line-height: 20px;
  padding: 16px 0;
  cursor: pointer;
  display: block;
}
.campaigns-dropdown.active ul {
  display: flex;
}
.campaigns-dropdown.active .more {
  display: none;
}
.campaigns-dropdown .less {
  display: none;
}
.campaigns-dropdown.active .less {
  display: block;
}
.campaigns-dropdown svg {
  width: 40px;
  height: 40px;
  padding: 5px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  z-index: 1000;
  background: white;
  border-radius: 6px;
}

.campaigns-dropdown ul li a:hover {
  text-decoration: underline;
}
.campaign_info .count {
  font-size: 40px;
  margin: 0;
  color: #42525a;
  font-weight: bold;
}
.campaign_info .comment {
  margin: 0;
  color: #42525a;
  font-size: 16px;
}
.campaign_detail {
  letter-spacing: 0.75px;
  color: rgba(66, 82, 90, 0.64);
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
}
.progress_bar .cur_progress {
  position: absolute;
  width: 0%;
  height: 100%;
  background: #fed202;
}
.campaigns-form .form-left img {
  width: 200px;
  height: 120px;
  border-radius: 6px;
}
.campaign_info {
  display: flex;
  justify-content: space-between;
}
.campaign_info div {
  flex: 1;
  text-align: center;
}
.get-started-title {
  padding: 40px 0;
  font-size: 48px;
  color: #42525a;
  border-bottom: solid 2px #f0f0f0;
  text-align: center;
}

.get-started-step {
  padding: 10px 0 0;
  color: rgba(66, 82, 90, 0.64);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.campaign-wrap {
  border-radius: 6px;
  border: solid 1px rgba(66, 82, 90, 0.3);
  font-size: 0;
  height: 100%;
}
.campaign-wrap .campaign-content {
  padding: 40px 5%;
}
.search-box {
  width: 565px;
  margin: 0 auto 75px;
  display: flex;
  max-width: 100%;
  padding: 0 10px;
  justify-content: center;
  position: relative;
}
.search-box svg {
  position: absolute;
  left: 30px;
  top: 12px;
}
.search-box svg path {
  fill: #bbb;
}
.search-box button {
  border-radius: 10px;
  background-color: #fed202;
  border: none;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #42525a;
  width: 127px;
  height: 48px;
  margin-left: 15px;
}
.search-box input {
  flex: 1;  
  border-radius: 10px;
  box-shadow: 0 4px 20px -8px rgb(0 0 0 / 30%);
  padding-left: 70px;
  font-size: 16px;
  border: none;
}
.campaign-wrap .campaign-content p.title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #42525a;
}
.campaign-wrap .campaign-content p.content {
  margin: 5px auto 30px;
  font-size: 16px;  
  color: #506b75;
}
.campaign-wrap .campaign-content a {
  border-radius: 6px;
  background-color: #fed202;
  font-size: 14px;
  color: #42525a;
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
}
.campaign-wrap img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.text-center {
  text-align: center;
}
.delivery-details {
  display: flex;
  position: relative;
  width: 100%;
}
.delivery-details .edit-button {
  position: absolute;
  right: 0;
  cursor: pointer;
  top: -24px;
  color: #42525a;
  line-height: 1.43;
  display: inline-block;  
  font-size: 14px;
  text-decoration: underline;
  font-weight: 600;
}
.delivery-details div {
  flex: 1;
}
.delivery-details p.title {
  font-size: 12px;
  color: rgba(66, 82, 90, 0.64);
  text-transform: uppercase;
  margin-top: 16px;
  margin-bottom: 8px;
}
.delivery-details p.subtitle {
  margin: 0;
  color: #333333;
  font-size: 16px;
  line-height: 1.5;
}
.delivery-details p.subtitle.edit-wrap {
  max-width: 250px;
  padding-right: 50px;
  position: relative;
}
.delivery-details p.subtitle.edit-wrap .edit-button {
  top: auto;
  bottom: 0;
  font-size: 10px;
  line-height: 2.4;
}
.donations {
  margin-top: 83px;
}
.overview-content.donations label.empty {
  width: 100%;
  text-align: center;
  display: block;
}
.donation {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: solid 2px rgba(0, 0, 0, 0.04);
}
.donation:first-child { 
  border-top: solid 2px rgba(0, 0, 0, 0.04);
}
.donation .user-img {
  background-color: rgba(254, 210, 2, 0.16);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  margin-right: 32px;
}
.donation .user-img svg {
  width: 35px;
  height: 35px;
}
.donation .user-img svg * {
  fill: #42525a;
}
.donation {
  display: flex;
  align-items: center;
}
.donation .user-detail p.title {
  font-size: 18px;
  font-weight: 600;  
  color: #42525a;
  margin-bottom: 8px;
  margin-top: 0;
}
.donation .user-detail p.content {
  font-size: 16px;
  color: #42525a;
  margin: 0;
}
.view-campaign .buttons-wrap {
  display: flex;
}
@media (max-width: 1023px) {
  .view-campaign .buttons-wrap {
    display: block;
  }
}
.view-campaign .det {
  margin-top: 30px;  
  color: #506b75;  
  font-weight: normal;
  margin-bottom: 13px;
  font-size: 21px;
}
.view-campaign .share-campaign {
  width: 290px;
  height: 58px;
  color: #42525a;
  background-color: #fed202;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;  
  margin-bottom: 15px;
}
.service-wrap {
  height: 100%;
  border-bottom: solid 1px #42525a;
  position: relative;
}
.Content.grey {
  background: #eeeef0;
}
.service-wrap span {
  width: 29px;
  height: 29px;
  font-size: 18px;
  border-radius: 50%;
  background-color: #de5646;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolio-sec {
  text-align: center;
}
.portfolio-sec .content {
  position: relative;
  margin: 0;
  font-size: 36px;
  font-weight: 600;
  color: #42525a;
  margin-left: 80px;
  text-align: left;
  margin-top: 40px;
}
.portfolio-sec .name {
  color: #1c976b;
  font-size: 33px;
  font-weight: bold;
  margin-top: 55px;
  margin-bottom: 0;
}

.portfolio-sec .subtitle {
  margin-top: 3px;
  color: #42525a;
  font-size: 21px;
}
.portfolio-sec span {
  font-size: 200px;
  font-style: italic;
  color: #fed202;
  position: absolute;
  left: -40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 40px;
  font-family: system-ui;
  line-height: 0px;
  width: 0px;
}
.portfolio-sec img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
.section-title {
  text-align: center;
  font-size: 55px;
  color: #42525a;
  font-family: 'OpenSans';
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 25px;
}
.section-content {
  font-size: 20px;
  font-weight: 300;  
  line-height: 1.6;
  letter-spacing: 0px;
  color: #42525a;
  margin-bottom: 60px;
}
.section-title span {
  color: #fed202;
}
.service-wrap img {
  width: 90%;
  max-width: 200px;
}
.gift-popup .close-popup {
  position: absolute;
  right: 50px;
  top: 50px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.pagination {
  display: flex;
  margin: 100px auto 30px;
  align-items: center;
  justify-content: center;
}
.pagination svg {
  margin: 0 25px;
}
.pagination svg.inactive * {
  fill: rgba(66, 82, 90, 0.24)
}
.pagination span {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #42525a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.pagination span.active {
  background-color: rgba(254, 210, 2, 0.16);
}
.mission-icons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: 40px;
}
.mission-icon {
  margin: 0 10px;
}
.mission-icon p {
  text-align: center;
}
.mission-icons img {
  width: 87px;
  height: 87px;
}
.view-campaign .progress_bar {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background-color: rgba(66, 82, 90, 0.64);
  margin-top: 18px;
  overflow: hidden;
  position: relative;
}
.view-campaign .campaign_donated {
  border-right: solid 0.5px #506b75;
  margin-right: 20px;
  text-align: center;
}

.view-campaign .campaign_donated .comment{
  text-align: center;
}

.view-campaign .campaign_goal {  
  text-align: center;
}
.view-campaign .campaign_funded {
  border-right: solid 0.5px #506b75;
  text-align: center;
  padding-right: 20px;
  margin-right: 20px;
}
.view-campaign .campaign_info .count {
  font-size: 50px;
  line-height: 1;
    color: #1c976b;
  margin-bottom: 10px;
}
.view-campaign .campaign_info .comment {
  color: #506b75;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.campaign_info .desc {
  color: #506b75;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #42525a;
  margin-top: 5px;
}

.service-wrap .service-title {
  margin-top: 32px;
  color: #42525a;
  margin-bottom: 0;  
  font-family: Bely;
}
.service-wrap .service-content {
  margin-top: 16px;
  margin-bottom: 32px;
  color: #42525a;
}
.service-title {
  margin-top: 28px;
  margin-bottom: 0;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 1;
}
.gift-content {
  color: #42525a;
}
.gift-title {
  color: #1c976b;
  font-size: 24px;
  margin-bottom: 21px;
  font-weight: bold;
  margin-top: 10px;
}
.Organizer-and-beneficiray .title {
  color: #506b75;
  font-size: 16px;
  font-weight: 600;
}
.Organizer-and-beneficiray .tile {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  color: #42525a;
}
.Organizer-and-beneficiray .tile a {
  margin-bottom: 16px;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.Organizer-and-beneficiray .tile img {
  width: 60px;
  min-width: 40px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
}
.Organizer-and-beneficiray .tile .detail {
  margin-right: 10px;
  margin-left: 10px;
}
.Organizer-and-beneficiray .tile .detail a {
  color: #42525a;
}
.Organizer-and-beneficiray .tile p.name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 18px;
}
.Organizer-and-beneficiray .tile p.position {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 16px;
  font-size: 12px;
}
button {
  cursor: pointer;
}
.view-campaign img.campaign-photo {
  width: 100%;
  min-height: 350px;
  max-height: 400px;
  object-fit: cover;
}
.settings-form-wrap form {
  margin-bottom: 20px;
}
.view-campaign {
  overflow: hidden;
}

.view-campaign .gift-meal {
  display: inline-block;
  background-color: #1c976b;
  color: white;
  width: 290px;
  height: 58px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;

  text-decoration: none;
  text-align: center;
  padding: 15px 5px;

  margin-bottom: 15px;
  margin-right: 36px;
}

.view-campaign .Content .Container {
  max-width:1920px;
  margin: 0 auto;
}

.donations-wrap .title {
  font-family: 'OpenSans';
  font-size: 30px;
  line-height: normal;
  letter-spacing: normal;
  color: #42525a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.donations-wrap label {
  font-size: 16px;
  color:  #42525a;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}

@media (max-width: 991px) {
  .donations-wrap .title {
    font-size: 18px;
  }
  .donations-wrap label {
    font-size: 16px;
  }
}


.quantity-selector {
  clear: both;
  width: 280px;
  height: 54px;
  border: solid 1px #333;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 0 0px;
  font-weight: normal;
}

.quantity-selector .button-side {
  padding: 0 10px;
}
.donations-wrap .quantity-selector input {
  width: 100px;
  padding: 5px 15px;
  border-radius: 0;
  border-left: none;
  border-right: none;
  text-align: center;
}
.popular-gifts button {  
  width: 135px;
  height: 54px;
  outline: none;
  border: none;
  border-radius: 6px;
  background: #1c976b;
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
  margin-right: 25px;
}
.donations-wrap {
  padding-right: 390px;
}

@media (max-width: 991px) {
  .donations-wrap {
    padding: 0 0;
  }

  .left-content {
    padding: 0 15px;
  }
}

.donations_nav header {
  box-shadow: none;
}
.donations_nav .main-menu {
  visibility: hidden;
}
.donations-wrap .right-sidebar {
  width: 390px;
  background-color: #f8fafb;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1000;
  height: 100%;
  padding: 20px 30px;
}
@media (min-width: 992px) {
  section[data-page="donation"] .footer {
    padding-right: 390px;
  }

  .donations-wrap .right-sidebar .sidebar-content {
    max-height: calc(100vh - 320px);
    overflow-y: auto;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  section[data-page="donation"] .footer .item-4-2-1 {
    width: 50%;
    margin-bottom: 20px;
  }
}
@media (max-width:991px) {
  .donations-wrap .right-sidebar {
    position: relative;
    width: 100%;
    margin-top: 40px;
  }

  .donations-wrap .left-content .return_link {
    display: none;
  }

  .donations-wrap .continue-but {
    margin-top: 5px;
  }
}

.donations-wrap .right-sidebar .title {
  margin: 0;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e4eaee;
}
.donations-wrap input[type="number"],
.donations-wrap input[type="text"] {
  width: 190px;
  height: 54px;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  border: solid 1px #1b2125;
}
.donations-wrap .accordion-header {
  padding-left: 30px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  color: #42525a;
}
.donations-wrap .accordion-header:before {
  content: '-';
  position: absolute;
  left: 0;
  top: calc(50% - 2px);
  height: 0;
  line-height: 0;
  font-size: 35px;
}
.donations-wrap .accordion-header.active:before {
  content: '+';
  font-size: 35px;
}
.donations-wrap .accordion-content {
  padding-left: 30px;
}
.input-wrap {
  position: relative;
  display: inline-block;
  clear: both;
}
.donations-wrap .input-wrap input {
  padding-left: 50px;
  text-align: left;

}
.donations-wrap .input-wrap:before {
  content: '$';
  position: absolute;
  font-size: 25px;
  font-weight: 500;
  height: 100%;
  width: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  justify-content: center;
}
.return_link {
  display: flex;
  align-items: center;
  color: #788995;
  text-decoration: none;
}
.second-help-wrap .input-wrap input {
  padding-right: 50px;
  text-align: center;
}
.continue-but {
  width: 250px;
  height: 54px;
  outline: none;
  border: none;
  border-radius: 6px;
  background: #1c976b;
  color: white;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 30px;
  max-width: 100%;
}
.second-help-wrap select {
  width: 190px;
  height: 54px;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  border: solid 1px #1b2125;
  padding: 0 30px;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}
.second-help-wrap .input-wrap:after {
    content: '';
    border: solid #1b2125;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    right: 15px;
    position: absolute;
    top: calc(50% - 8px);
}
.quantity-selector .button {
  width: 18px;
  height: 18px;
  background-color: #42525a;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.donations-wrap .product-wrap {
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.donations-wrap .product-wrap img { 
  margin-right: 35px;
}
.donations-wrap .product-wrap .product-title {
  color:  #788995;
  font-size: 16px;
  line-height: 1.5;
  margin:  0;
}
.donations-wrap .product-wrap .product-subtitle {
  color:  #788995;
  font-size: 10px;
  line-height: 2.4;
  margin:  0;
}

.donations-wrap .product-wrap .product-price {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 6px;
  color:  #788995;
  font-weight: 600;
  line-height: 1.71;
}
.donations-wrap p.comment {
  font-size: 12px;
  font-weight: 300;
  color: #788995;
  margin-top: 5px;
}
.donations-wrap hr +  p.comment {
  margin-bottom: 10px;
  color: #42525a;
} 
.price-cal-wrap {
  padding-bottom: 30px;
  border-bottom: 1px solid #e4eaee;
  margin-bottom: 30px;
}
.price_wrap {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #788995;
  margin-top: 5px;
}
.price_wrap.total_price {
  font-size: 16px;
  color: #1b2125;
  font-weight: 600;
}
.gift-buttons {
  margin-bottom: 50px;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: #42525a;
  justify-content: space-between;
  text-decoration: underline;
}
.gift-buttons span {
  cursor: pointer;
}
.gift-content p.title {
  font-size: 19px;
  margin-top: 8px;
  margin-bottom: 0;
  color: #42525a;
  font-weight: 600;
}
.gift-content p.desc {
  font-size: 16px;
  color: #42525a;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 300;
}
@media (max-width: 767px) {
  .campaigns-title, .campaign-title {
    font-size: 32px;
  }

  .signup_wrap input,
  .signup_wrap select { 
    font-size: 14px;
    margin-bottom: 15px;
  }
  .filter-modal {
    width: 250px;
    padding: 30px 10px;
  }
  .filter-item:first-child .filter-modal {
    left: 0;
  }
  .filter-item:first-child .filter-modal:after {
    left: 50px;
  }
  .result-box .MuiGrid-item {
    width: 100%;
  }
  .section-title {
    font-size: 32px;
  }
  .get-started-title {
    padding: 30px 0;
    font-size: 32px;
  }
  .get-started-step {
    font-size: 22px;
  }
  .service-title {
    font-size: 26px;
  }
  .portfolio-sec .content {
    font-size: 28px;
    margin-left: 0;
  }
  .portfolio-sec .name {
    font-size: 25px;
  }
  .portfolio-sec span {
    display: none;
  }
  .portfolio-sec .subtitle {
    font-size: 18px;
  }
}

/* Landing */
.page-container {
  padding-right: 80px;
  padding-left: 80px;
  max-width: 1920px;
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
  .page-container {
    padding-right: 40px;
    padding-left: 40px;
  }
}


@media screen and (max-width: 767px) {
  .page-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}


.flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flexbox.offset-25 {
  margin-right: -25px;
  margin-left: -25px;
}

.flexbox.offset-10 {
  margin-right: -10px;
  margin-left: -10px;
}

.item-4-2-1 {
  width: 25%;
}

.item-6-3-2 {
  width: 16.66%;
}

.item-3-1-1 {
  width: 33.33%;
}

.item-55 {
  width: 55%;
}

.item-45 {
  width: 45%;
}

.item-half {
  width: 50%;
}

@media screen and (max-width: 991px) {
  .item-4-2-1 {
    width: 50%;
  }
  .item-6-3-2 {
    width: 33.33%;
  }
  .item-3-1-1 {
    width: 100%;
  }
  .item-55 {
    width: 100%;
  }

  .item-45 {
    width: 100%;
  }  

  .item-half {
    width: 100%;
  }
  
}


@media screen and (max-width: 767px) {
  .item-4-2-1 {
    width: 100%;
  }
  .item-6-3-2 {
    width: 50%;
  }
}


.item-box {
  padding-right: 25px;
  padding-left: 25px;
}

@media screen and (max-width: 991px) {
  .item-box {
    margin-bottom: 40px;
    text-align: center;
  }

  .item-box .image-box {
    margin: 0 auto;
  }
}


@media screen and (max-width: 767px) {
  .item-box {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.full-image {
  width: 100%;
  height: auto;
}

.image-box {
  position: relative;
  z-index: 1;
  font-size: 0;
}

.image-box.max-200 {
  max-width: 200px;
}

.sectionsmalltitle {
  font-family: Bely, serif;
  color: #1c976b;
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  letter-spacing: 0px;
}

h3.sectionsmalltitle {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sectiontext {
  font-family: 'Open Sans', sans-serif;
  color: #42525a;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
}

.section {
  position: relative;
}

#section1 {
  padding-top: 70px;
  padding-bottom: 100px;
  background-color: #eeeef0;
}

@media (max-width: 767px) {
  #section1 {
    padding-top: 50px;
    padding-bottom: 80px;
  }  
}


.item-55 {
  width: 55%;
}

@media screen and (max-width: 991px) {
  .item-55 {
    width: 100%;
  }
}



@media screen and (max-width: 991px) {
  .image-box2 {
    margin-right: -40px;
    margin-left: -40px;
  }
}


@media screen and (max-width: 767px) {
  .image-box2 {
    margin-right: -20px;
    margin-left: -20px;
  }
}


.item-45 {
  width: 45%;
}

@media screen and (max-width: 991px) {
  .item-45 {
    width: 100%;
  }
}


.item-box4 {
  padding-left: 55px;
}

@media screen and (max-width: 991px) {
  .item-box4 {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .item-box4 .item-25:nth-child(3){
    display: none;
  }
}


.heading-box {
  margin-bottom: 50px;
  text-align: center;
}

.heading-box.left {
  text-align: left;
}


@media screen and (max-width: 991px) {
  .heading-box.left {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}


.sectiontitle {
  font-family: 'bely';
  color: #484848;
  font-size: 60px;
  line-height: 60px;
  font-weight: 400;
}

.sectiontitle.green {
  color: #1c976b;
}

h2.sectiontitle {
  margin-top: 0px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .sectiontitle {
    font-size: 48px;
    line-height: 55px;
  }
}
.sectionbigtext {
  font-family: 'Open Sans', sans-serif;
  color: #42525a;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.18;
}

.sectionbigtext.bold {
  font-weight: 600;
}

.context-box1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.item-25 {
  width: 25%;
}

.sectionsmalltext {
  font-family: 'Open Sans', sans-serif;
  color: #42525a;
  font-size: 15px;
  letter-spacing: 0px;
}

#section2 {
  padding-top: 150px;
}

#section22 {
  padding: 100px 0 ;
}


@media (max-width: 991px) {
  #section2 {
    padding-top: 50px;
  }

  #section22 {
    padding: 50px 0;
  }
}

@media (max-width: 767px) {
  .context-box1 .item-25 {
    width: 33.333%;
    padding: 0 10px;
  }

  .overview-submenu button {
    font-size: 14px;
    padding: 5px;
    height: 33px;
    color: rgba(66, 82, 90, 0.64);
  }
  .overview-submenu button.active {
    color: #42525a;
  }
  .campaign_info .comment {
    font-size: 14px;
    padding: 0 5px;
  }
  .campaigns-form .progress_bar {
    margin-top: 30px;
  }
  .campaigns-form {

    padding: 39px 33px 36px 36px;
  }
  .overview .campaigns-form .form-left img {
    margin: 0 auto;
  }
  .overview-content.overview {
    padding: 0;
    margin-top: 30px;
  }
  .campaigns-form {
    display: block;
  }
  .campaigns-form .form-right {
    margin-left: 0;
  }
  #section2 {
    padding-top: 20px;
  }

  #section22 {
    padding: 20px 0;
  }
  .campaign-status-wrap {
    display: block;
  }
  .overview-content.overview .share-campaign {
    margin-left: 0;
  }
  .meals-detail-wrap, .delivery-details {
    display: block;
  }
  .meals-detail-wrap .campaign_info {
    display: block;
    text-align: center;
    min-width: auto;
    margin-top: 40px;
  }
  .campaign_info .count {
    padding: 0 5px;
  }
  .delivery-details .edit-button {
    top: -35px;
  }
}

.item-box5 {
  padding-right: 10px;
  padding-left: 10px;
}

@media screen and (max-width: 991px) {
  .item-box5 {
    margin-bottom: 20px;
  }
}

.bottom-box {
  margin-top: 50px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .bottom-box {
    margin-top: 30px;
  }
}

.section-grouplink {
  display: inline-block;
  width: 330px;
  margin-top: 20px;
  margin-right: 25px;
  margin-left: 25px;
  padding: 12px 10px;
  border-radius: 6px;
  background-color: #fed202;
  font-family: 'Open Sans', sans-serif;
  color: #42525a;
  line-height: 1.43;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}
.section-grouplink1 {
  display: inline-block;
  width: 300px;
  margin-top: 40px;
  padding: 12px 10px;
  background-color: #fed202;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.05px;
  color: #42525a;
  border: none;
  line-height: 1.43;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.hidden {
  display: none;
}
.red {
  color: red;
}
.price_gift p.red {
  font-weight: 600;
}
.section-grouplink.green {
  background-color: #1c976b;
  color: #fefefe;
}

@media screen and (max-width: 479px) {
  .section-grouplink {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

}

#section3 {
  padding-top: 60px;
  padding-bottom: 80px;
}

#section3 .heading-box {
  max-width: 580px;
  margin: 0 auto;
  margin-bottom: 50px;
}

#section3 .image-box {
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  #section3 {
    padding-top: 60px;
    padding-bottom: 60px;
  }  
}

.item-campaign {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .item-campaign {
    margin-bottom: 30px;
  }
}

.item-wrapper {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(66, 82, 90, 0.3);
  border-radius: 6px;
}

.info-box {
  padding: 30px;
  text-align: center;
}

.section-link {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  padding: 12px 10px;
  border-radius: 6px;
  background-color: #fed202;
  font-family: 'Open Sans', sans-serif;
  color: #42525a;
  line-height: 1.43;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}

.item-box3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 60px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .item-box3 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.button-box1 {
  position: absolute;
  left: 60px;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 300px;
}

@media screen and (max-width: 991px) {
  .button-box1 {
    position: static;
    left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .button-box1 {
    margin-right: auto;
    margin-left: auto;
  }
}

#section7 {
  padding-top: 150px;
  padding-bottom: 150px;
}

@media screen and (max-width: 991px) {
  #section7 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #section7 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

#section8 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media screen and (max-width: 991px) {
  #section8 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  #section8 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

#section6 {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #eeeef0;
}


@media screen and (max-width: 991px) {
  #section6 {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}

#section6 .heading-box {
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  #section6 .heading-box {
    margin-bottom: 45px;
  }
  
}

#section6 .item-box {
  height: 100%;
  position: relative;
}

#section6 .item-wrapper {
  height: 100%;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #42525a;
}

@media (max-width: 991px) {
  #section6 .item-wrapper {
    max-width: 300px;
    margin: 0 auto;
  }

  #section6 .item-box {
    height: auto;
  }
}

.footer {
  padding-top: 100px;
  padding-bottom: 20px;
  background-color: #42525a;
}

.footer-bottom-box {
  margin-top: 200px;
}

.footer-text {
  font-family: 'Open Sans', sans-serif;
  color: #fefefe;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0px;
  text-decoration: none;
}

.footer-heading-box {
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .footer-heading-box {
    margin-top: 40px;
  }
}
.footer-title {
  font-family: 'Open Sans', sans-serif;
  color: #fefefe;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0px;
}

.footer-text {
  font-family: 'Open Sans', sans-serif;
  color: #fefefe;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0px;
  text-decoration: none;
}

.overlay-box {
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 60px;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .filter-item {
    margin-left: 20px;
  }
  .overlay-box {
    position: relative;
    bottom: 0;
  }
  .overlay-box .text-box {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.text-box {
  width: 500px;
  margin-left: 80px;
}

@media screen and (max-width: 767px) {
  .text-box {
    width: 100%;
    margin-left: 0px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }
}

.bannertitle {
  color: #fff;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 70px;
  line-height: 1.03;
}

.bannersubtitle {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .bannertitle {
    color: #42525a;
    font-size: 40px;
    line-height: 1.05;
  }
  .bannersubtitle {
    color: #42525a;
  }
}

.banner-link {
  display: inline-block;
  width: 330px;
  margin-top: 20px;
  padding: 12px 10px;
  border-radius: 6px;
  background-color: #fed202;
  font-family: 'Open Sans', sans-serif;
  color: #42525a;
  font-size: 18px;
  line-height: 1.43;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05px;
  text-decoration: none;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .banner-link {
    width: 200px;
  }
}


@media screen and (max-width: 479px) {
  .banner-link {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}


#section2 .item-25 .sectionsmalltext {
  max-width: 150px;
}

#section5 {
  padding-top: 130px;
  padding-bottom: 60px;
}

#section5 .info-box a {
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  margin-top: 0px;
  color: #42525a;
  text-decoration: none;
}

#section5 .bottom-box a {
  font-family: 'OpenSans';
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0px;
  text-align: center;
  color: #42525a;
  text-decoration: none;
}

@media (max-width: 991px) {
  #section5 {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  #section5 {
    padding-top: 60px;
    padding-bottom: 80px;
  } 
}

.image-box3 {
  width: 90px;
  margin: 0 auto;
}

.text-box2 {
  position: relative;
  text-align: center;
  padding-top: 70px;

  padding-left: 250px;
  padding-right: 120px;
  max-width:1600px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .text-box2 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .text-box2 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.text-box2 .sectiontext {
  font-family: 'OpenSans';
  font-size: 36px;

  font-weight: 600;
  letter-spacing: 0;
  color: #42525a;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .text-box2 .sectiontext {
    font-size: 25px; 
  }
}

.text-box3 .sectionsmalltext {
  font-family: 'OpenSans';
  font-size: 21px; 
  letter-spacing: 0;
  color: #42525a;
}

.text-box3 .sectionbigtext {
  font-family: 'OpenSans';
  font-size: 33px;
  font-weight: bold;
  letter-spacing: 0;
  color: #1c976b;  
}

.image-box4 {
  position: absolute;
  top: 50px;
  left: 160px;
}

@media (max-width: 991px) {
  .image-box4 {
    display: none;
  }  
}

.testimonial-box {
  padding-top: 80px;
  padding-bottom: 90px;
  background-color: #eeeef0;
  height: 100%;
}

@media (max-width: 767px) {
  .testimonial-box {
    padding-top: 60px;
    padding-bottom: 100px;
  }  
}

.text-box3 {
  text-align: center;
}

.Carousel-buttonWrapper-19 {
  z-index: 20;
}

h3.footer-title {
  margin-top: 0;
  margin-bottom: 0;
}

.form-row input {
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  border: 1px solid rgba(250,250,250,0.2);
  padding: 12px 15px;
  color: #fefefe;

}


.form-row input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rbga(66, 82, 90, 0.64);
}

.item-badge {
  font-family: 'OpenSans';
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;

  color: #fefefe;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
}

#camp_1 .item-badge {
  background: #de5646;  
}

#camp_1 .sectionsmalltitle {
  color: #de5646;
}

#camp_2 .item-badge {
  background: #1c976b;  
}

#camp_2 .sectionsmalltitle {
  color: #1c976b;
}

#camp_3 .item-badge {
  background: #1b6c9d;  
}

#camp_3 .sectionsmalltitle {
  color: #1b6c9d;
}

#camp_4 .item-badge {
  background: #a78951;  
}

#camp_4 .sectionsmalltitle {
  color: #a78951;
}

#section6 .image-box {
  margin-bottom: 30px;
}

.gift-popup-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 10000;
  top: 0;
  background-color: rgba(0, 0, 0, 0.48);
}
.gift-popup {
  overflow: scroll;
  padding: 55px;
  position: absolute;
  overflow: scroll;
  background: white;
  width: 80%;
  margin: 80px 10%;
  height: calc(100% - 160px);
  border-radius: 6px;
  display: block;
}
.gift-tile {
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
}
.gift-tile svg { 
  background-color: rgba(254, 210, 2, 0.16);
  padding: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 32px;
}
.gift-tile p.title {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  color: #42525a;
  margin-bottom: 8px;
}
.gift-tile p.desc {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: #42525a;
}
.gift-popup p.gift-title {
  font-size: 48px;
  color: #42525a;
  text-align: center;
  margin: 0;
}
.devlivery-wrap p.title {
  color: #1c976b;
  font-size: 24px;
  margin-bottom: 7px;
  font-weight: bold;
  margin-top: 0;
}
.devlivery-wrap .item-title {
  font-size: 19px;
  margin-top: 8px;
  margin-bottom: 0;
  color: #42525a;
  font-weight: 600;
}
.devlivery-wrap .item-content {
  font-size: 16px;
  color: #42525a;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 300;
}
.devlivery-wrap .comment {
  font-size: 10px;
  color: #42525a;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.2;
}
.placeholder_img {
  height: 365px;
  display: flex;
  align-items: center;
  padding: 5%;
  justify-content: center;
}
.placeholder_img img {
  max-height: 100%;
  max-width: 100%;
}
.campaign-wrap .placeholder_img {
  height: 400px;
  padding: 20px;
}
.campaign-wrap .placeholder_img img {
  object-fit: contain;
  max-height: 100%;
}
.main-nav a {
  color: #42525a;
  cursor: pointer;
  margin-right: 35px;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
}
.main-nav a.current {
  padding-bottom: 3px;
  border-bottom: solid 2px #42525a;
}

#section4 .indicators {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 10;
}

#section4 .react-slideshow-container+ul.indicators li {
  width: 12px;
  height: 12px;
  padding: 5px 10px
}

#section4 .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
  width: 12px;
  height: 12px;
  background: rgb(66, 82, 90);
}
#section4 .react-slideshow-container+ul.indicators .each-slideshow-indicator.active:before {
  background: #fed202;

}

.mainLogo {
  margin-right: 60px;
}

@media (max-width: 1280px) {
  .mainLogo {
    margin-right: 0px;
    width: 100px;
  } 
}

@media (max-width: 991px) {
  .mainLogo {
    margin-right: 0px;
    width: 80px;
  } 
}

@media (max-width: 440px) {
  .mainLogo {
    margin-right: 0px;
    width: 60px;
  } 

  .MuiDrawer-paper {
    width: 100%;
  }
}
option:disabled {
  color: red;
}
.link-icon {
  margin-left: 30px;
  margin-right: 30px;
}
.share-campaign-modal svg.link-wrap-facebook * {
  fill: #3b5998;
}
.share-campaign-modal svg.link-wrap-email * {
  fill: #42525a;
}
.share-campaign-modal svg.link-wrap-twitter * {
  fill: #00acee;
}
.share-campaign-modal svg.link-wrap-linkedin * {
  fill: #0072b1;
}
.share-campaign-modal svg *{
  fill: rgba(66, 82, 90, 0.24);
}
.loadWrap {
  position: fixed;
  left: 0;
  z-index: 10000;
  top: 0;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.image-box img {
  max-height: calc(100vh - 81px);
  object-fit: cover;
}


.item-box6 {
  width: 100%;
  background-color: rgba(254, 210, 2, 0.25);

  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .item-box6 {
    height: 100%;
  }
}

.item-box6 .item-box {
  padding: 40px 50px;
  margin-bottom: 0;
  max-width: 500px;
}

.item-box6 .bannersubtitle {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.22;
  letter-spacing: normal;
  color: #42525a;
}

.item-box6 .bannertitle {
  font-family: Poppins;
  font-size: 54px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #42525a;
}

@media (max-width: 991px) {
  #section11 .flexbox {
    flex-direction: column-reverse;
  }

  .item-box6 .bannertitle {
    font-size: 42px;
  }  
}


#section12 {
  padding-top: 70px;
  padding-bottom: 50px;
}


@media (max-width: 767px) {
  #section12 {
    padding-top: 50px;
    padding-bottom: 50px;
  }  
}

#section12 .sectionbigtext,
#section14 .sectionbigtext {  
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0px;  
  color: #42525a;
}

#section12 .heading-box .sectiontitle {
  font-family: bely;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;  
  color: #484848;
}

#section12 .flexbox .sectiontitle,
#section14 .flexbox .sectiontitle {
  font-family: bely;
  font-size: 55px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #42525a;
}

@media (max-width: 991px) {
  #section12 .heading-box .sectiontitle {
    font-size: 42px;
  }

  #section12 .flexbox .sectiontitle,
  #section14 .flexbox .sectiontitle {    
    font-size: 40px;
  }
}

#section12 .sectionsmalltext {
  font-family: bely;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #42525a;
}

#section12 .content-box .item-box {
  padding: 50px 0;
}

@media (max-width: 991px) {
  #section12 .content-box .item-box {
    padding: 0px 0;
  } 

  #section12 .content-box .image-box {
    margin-top: 20px;
  }
}
.right_arrow p {
  position: relative;
}
.right_arrow svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.text-box4 {
  padding: 15%;
}
.bottom_box * {
  font-family: Poppins!important;  
  font-size: 16px!important;
  font-weight: 500!important;  
  color: #42525a!important;  
  opacity: 0.8;
}
@media (max-width: 991px) {
  .text-box4 {
    padding: 0 0px;
  } 
}
.singout_mobile svg {
  display: none;
}
.singout_mobile a {
  cursor: pointer;
  color: #42525a;
  font-size: 24px;
  font-family: Bely;
}
.singout_mobile a:hover {
  text-decoration: underline;
}

.view-contact {
  padding: 150px 30px 300px;
}

@media (max-width: 768px) {
  .view-contact {
    padding: 40px 20px 40px;
  }  
}

#section_contact {
  max-width: 1000px;
  margin: 0 auto;
  background-color:rgba(240, 240, 240, 0.4);
  padding: 100px 20px 110px;   
}

@media (max-width: 768px) {
  #section_contact {
    padding: 40px 20px 40px;
  }  
}

.view-contact form {
  width: 100%;
  max-width: 530px;
  margin: 0 auto;
}

.view-contact form input, 
.view-contact form textarea {
  font-family: 'OpenSans';
  font-size: 16px;
  line-height: 24px;
  color: #333;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  outline: none;    
  padding: 12px 20px;
  border: none;
}

.view-contact .form-row {
  margin-bottom: 10px;
}

.view-contact .form-button {
  margin-top: 30px;
  text-align: center;
}

.section_heading {
  max-width: 780px;
  margin: 0 auto 40px;
  text-align: center;
}

.section_heading h1 {
  font-family: bely;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #484848;
  margin: 20px 0;
}

.section_heading h1.yellow {
  color: #fed202;
}

.section_heading .section_text {
  font-family: 'OpenSans';
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0px;
  text-align: center;
  color: #42525a;
}

.error_text {
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0px;
  text-align: center;
  color: red; 
}

.success_text {
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0px;
  text-align: center;
  color: rgb(40, 207, 115); 
}

@media (max-width: 768px) {
  .section_heading h1 {
    font-size: 40px;
  }
  
  .section_heading .section_text {
    font-size: 18px;
  }
}

.view-contact button {
  width: 200px;
  padding: 14px 73px 14px 75px;
  border: none;
  border-radius: 6px;
  background-color: #fed202;
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #42525a;
  text-transform: uppercase;
  outline: none;
}

.footer-link {
  margin-bottom: 5px;
}







#ic_signupform .elcontainer {
  text-align: left;
  max-width: 450px;
  border-radius: 3px;  
  font-size: 12px;
  color: #5a5a5e;
  font-family: verdana;
}

#ic_signupform .elcontainer.center-aligned .formEl { 
 margin-right: auto;
 margin-left: auto; 
}

#ic_signupform .elcontainer.right-aligned .formEl { 
 margin-left: auto; 
}

#ic_signupform .form-header {
  
     display: none;
  
  background: #ffffff;
  margin-top: -9px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 9px; /* using padding on purpose */
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-left: 30px;
  border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
  text-align: center;
  font-size: 150%;
  color: #333333;
  border-bottom: 1px none #dddddd;
}

#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-input label,
#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-dropdown label,
#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-checkbox h3,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-input label,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-dropdown label,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-checkbox h3 {
  width: 30%;
}
    
#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-checkbox h3,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-checkbox h3 {
  line-height: 3em; 
}

#ic_signupform .elcontainer.tight.inline-label-left .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.tight.inline-label-left .formEl.fieldtype-checkbox h3,
#ic_signupform .elcontainer.tight.inline-label-right .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.tight.inline-label-right .formEl.fieldtype-checkbox h3 {
  line-height: 2em; 
}

#ic_signupform .elcontainer.generous.inline-label-left .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.generous.inline-label-left .formEl.fieldtype-checkbox h3,
#ic_signupform .elcontainer.generous.inline-label-right .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer.generous.inline-label-right .formEl.fieldtype-checkbox h3 {
  line-height: 4em; 
}

#ic_signupform .elcontainer.inline-label-left .formEl input[type="text"],
#ic_signupform .elcontainer.inline-label-left .formEl select,
#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-radio .option-container,
#ic_signupform .elcontainer.inline-label-left .formEl.fieldtype-checkbox .option-container,
#ic_signupform .elcontainer.inline-label-right .formEl input[type="text"],
#ic_signupform .elcontainer.inline-label-right .formEl select,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-radio .option-container,
#ic_signupform .elcontainer.inline-label-right .formEl.fieldtype-checkbox .option-container {
  width: 70%;
}

#ic_signupform .elcontainer.hidden-label .formEl.required:before {
  display: none;
}
    
#ic_signupform .elcontainer .formEl {
  font-size: 1em;
  right: 0;
  padding: 0;
}

#ic_signupform .elcontainer .formEl.fieldtype-input label,
#ic_signupform .elcontainer .formEl.fieldtype-dropdown label,
#ic_signupform .elcontainer .formEl.fieldtype-radio h3,
#ic_signupform .elcontainer .formEl.fieldtype-checkbox h3 {
  font-size: 100%;
  font-weight: bold;
  color: #5a5a5e;
}

#ic_signupform .elcontainer .formEl.fieldtype-input input[type="text"],
#ic_signupform .elcontainer .formEl.fieldtype-dropdown select {
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid rgba(250,250,250,0.2);
  padding: 12px 15px;
  color: #fefefe;
}
    
#ic_signupform .elcontainer .formEl.fieldtype-input input[type="text"],
#ic_signupform .elcontainer .formEl.fieldtype-dropdown select,
#ic_signupform .elcontainer .formEl.fieldtype-radio label,
#ic_signupform .elcontainer .formEl.fieldtype-checkbox label {
  font-size: 14px;
  line-height: 18px;
}

#ic_signupform .elcontainer .formEl input[type="text"]::-moz-placeholder {
  color: #bdbdbf;
  font-family: inherit;
}

#ic_signupform .elcontainer .formEl input[type="text"]::-webkit-input-placeholder {
  color: #bdbdbf;
  font-family: inherit;
}

#ic_signupform .elcontainer .formEl input[type="text"]:-ms-input-placeholder {
  color: #bdbdbf;
  font-family: inherit;
}

#ic_signupform .elcontainer .formEl input[type="text"],
#ic_signupform .elcontainer .formEl select,
#ic_signupform .elcontainer .formEl .option-container label {
  color: #bdbdbf;
  font-family: inherit;
}
    
#ic_signupform .elcontainer.inline-button .submit-container {
 display: inline-block;
 box-sizing: border-box;
 right: -.5em;
 padding: 0 1em 0 0;
 position: relative;
 vertical-align: bottom;
 margin-bottom: 1em;
}
 
#ic_signupform .elcontainer.inline-button.tight .sortables {
 margin-bottom: -.5em;
}
 
#ic_signupform .elcontainer.inline-button .sortables {
 margin-bottom: -1em;
}
 
#ic_signupform .elcontainer.inline-button.generous .sortables {
 margin-bottom: -1.5em;
}

#ic_signupform .elcontainer .submit-container {
  text-align: center;
}

#ic_signupform .elcontainer .submit-container input[type="submit"] {
  background: #042525;
  border: 1px solid #ffffff;
  border-radius: 10px;
  line-height: 1em;
  padding: 11px 44px;
  color: #ffffff;
  font-size: 100%;
  font-family: inherit;
  width: 100%;
  text-transform: uppercase;

  display: none;
}

.view-contact form input::-moz-placeholder,
.view-contact form textarea::-moz-placeholder {
  font-family: 'OpenSans';
  font-size: 16px;
  color: rgba(66, 82, 90, 0.64);
}

.view-contact form input::-webkit-input-placeholder,
.view-contact form textarea::-webkit-input-placeholder {
  font-family: 'OpenSans';
  font-size: 16px;
  color: rgba(66, 82, 90, 0.64);
}

.view-contact form input:-ms-input-placeholder,
.view-contact form textarea::-ms-input-placeholder {
  font-family: 'OpenSans';
  font-size: 16px;
  color: rgba(66, 82, 90, 0.64);
}


@media (max-width: 991px) {

  section[data-page="donation"] .Content {
    padding: 0 0;
  }

  section[data-page="donation"] .Content .Container {
    padding: 0 0;
  }

  .popular-gifts-wrap {
    margin-top: 25px;
  }
  .popular-gifts {
    max-width: 350px;
  }

  .popular-gifts button {
    margin-bottom: 20px;
  }

  #donation_second {
    padding-left: 0px;
    margin-top: 25px;
  }

  section[data-page="donation"] .quantity-selector {
    font-size: 16px;
  }

  .donations-wrap hr + p.comment {
    margin-top: 30px;
  }
}


#section13 {
  padding: 70px 0;
}

#section13 .page-container {
  max-width: 1140px;
}

#section14 {
  padding: 30px 0 150px;
}

@media (max-width: 991px) {
  #section13 {
    padding: 40px 0;
  }  
}

.text-box5 {
  margin-top: 30px;
}

.text-box5 .sectionbigtext {
  font-family: bely;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0px;
  text-align: center;
  color: #42525a;
}

@media (max-width: 767px) {
  .text-box5 .sectionbigtext {
    font-size: 32px;
  }
}

#section14 .image-box {
  padding-right: 60px;
}

.text-box6 {
  padding-right: 10%;
}

@media (max-width: 991px) {
  #section14 .item-box {
    padding-right: 0px;
    padding-left: 0px;
  }

  #section14 .image-box {
    padding-right: 0;
  }

  .text-box6 {
    padding-right:0%;
    margin-top: 30px;
  }
}

#section15 {
  padding: 70px 0;
}

#section15 .flexbox {

}

.text-box10 {
  width: calc(100% - 520px);
  padding-right: 50px;
}

#section15 .flexbox .image-box {
  width: 520px;
}

@media (max-width: 991px) {
  .text-box10 {
    width: calc(100% - 400px);
    padding-right: 20px;
  }
  
  #section15 .flexbox .image-box {
    width: 400px;
  }
}

@media (max-width: 767px) {
  .text-box10 {
    width: 100%;
    padding-right: 0px;
  }
  
  #section15 .flexbox .image-box {
    width: 100%;
  }
}

#section14 .sectiontext,
#section15 .sectiontext {
  font-family: 'OpenSans';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #42525a;
}

#section15 .sectionbigtext {
  font-family: bely;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0px;
  text-align: center;
  color: #42525a;
}

.text-box7 {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  #section15 {
    padding: 40px 0;
  }  
}


@media (max-width: 767px) {
  #section15 .sectionbigtext {
    font-size: 32px;
  }
}

.text-box11 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#image-box5 {
  width: 520px;
  display: table;  
}

.text-box12 {
  width: calc(100% - 590px);
}

@media (max-width: 991px) {

  .text-box11 {
    flex-direction: column-reverse;
  }

  #image-box5 {
    max-width: 420px;    
  }

  .text-box12 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #image-box5 {
    float: none;
    max-width: 100%;
    width: 100%;
    padding-left: 0px;
    margin-bottom: 30px;
  }
}


#section14 .page-container,
#section15 .page-container {
  max-width: 1340px;
}

#section14 .item-box {
  padding-left: 0;
  padding-right: 0;
}

.slick-dots {
  position: absolute;
  bottom: 20px !important;
}

.slick-dots li button:before {
  color: #42525a!important;
  font-size: 18px!important;
}

.slick-dots li.slick-active button:before {
  color: #fed202!important;
}

.text-box8 .sectiontext a,
.text-box8 .sectiontext span {
  color: #fed202;
}

.popular-gifts-wrap {
  margin-top: 20px;
}

#money_box {
  margin-top: 60px;
}

#helping_box {
  margin-top: 90px;
}

@media (max-width: 767px) {
  #helping_box {
    margin-top: 20px;
  }
}

.donations-wrap .input-label {
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #42525a;
}

#helping_box {
  display: flex;
  flex-wrap: wrap;
  max-width: 810px;
}


#helping_box .text-box {
  width: 60%;
  margin-left: 0;
  background-color: rgba(254, 210, 2, 0.25);
  padding: 50px 70px 50px 35px;
}

#helping_box .image-box {
  width: 40%;
} 

#helping_box .image-box img {
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  #helping_box .text-box {
    width: 100%;
  }

  #helping_box .image-box {
    display: none;
  } 
}

@media (max-width: 767px) {
  #helping_box .text-box {
    width: 100%;
    margin-left: 0;
    background-color: rgba(254, 210, 2, 0.25);
    padding: 50px 35px 50px 35px;
  }
}

#helping_box .description {
  font-family: 'OpenSans';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #42525a;
}

.price_gift {
  margin-top: 10px;
}

.price_gift .checkbox-wrap {
  padding-left: 0;
  padding-right: 0;
}

.price_gift .checkbox-wrap label {
  margin-bottom: 0;

  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: #42525a;
}

.price_gift .checkbox-wrap a {
  color: inherit;
  text-decoration: underline;
  font-style: italic;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}


.form-error {
  color: red;
  font-style: italic;
  margin-bottom: 20px;
  text-align: center;
}

.delivery-item {
  margin-bottom: 13px;
}

.delivery-item label {
  font-family: 'OpenSans';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: rgba(66, 82, 90, 0.64);
  text-transform: uppercase;
}

.delivery-item div {
  font-family: 'OpenSans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333;
  margin-top: 3px;
}

.delivery-item p {
  font-family: 'OpenSans';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.delivery-heading {
  margin-bottom: 20px;
}

.delivery-heading label {
  font-family: 'OpenSans';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #42525a;
}

.delivery-heading a {
  font-family: 'OpenSans';
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #42525a;
  margin-left: 10px;
}

.meals-detail-wrap .campaign_goal .count {
  color: #1c976b;
}

#section5 .sectionbigtext {
  min-height: 50px;
}

.donation_alert {
  color: red;
  margin-bottom: 5px;
}

.form-content-check {
  margin-top: 30px;
}

.form-content-check .MuiFormControlLabel-label {
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #42525a;
}

.delivery-copy {
  font-family: 'OpenSans';
  font-size: 12px;
  letter-spacing: 0.75px;
  color: #42525a;
}
.second-helpings {
  margin-bottom: -40px;
}
.second-helpings .buttons-wrap a {
  color: #42525a;
  background: #fed202;
  margin-bottom: 50px;
  margin-top: 20px;
}
.only-second-helping #helping_box {
  flex-direction: row-reverse;
  margin-top: 0;
  max-width: 100%;
  align-items: center;
}
.only-second-helping #helping_box .image-box img {
  border-radius: 10px;
  max-height: 400px;
}
.only-second-helping #helping_box .image-box {
  width: 50%;
}
.only-second-helping #helping_box .text-box {
  width: 50%;
  background: none;
}
.only-second-helping #helping_box .image-box {
  display: block;
}
@media (max-width: 767px) {
  .only-second-helping #helping_box {
    flex-direction: column-reverse;
  }
  .only-second-helping #helping_box .text-box, 
  .only-second-helping #helping_box .image-box {
    width: 100%;
  }
}
.campaign-goal {
  color: #1c976b;
  font-size: 20px;
  font-family: bely;
}

.btn-complete, .btn-profile, .btn-share {
  width: 207px;
  height: 48px;
  border-radius: 6px;
  background-color: #fed202;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #42525a;
}

.btn-share, .btn-profile {
  margin-left: 20px;
}

.btn-meals {
  width: 207px;
  height: 48px;
  border-radius: 6px;
  justify-content: center;
  border: none;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;  
  margin-top: 0;
  margin-left: 0px;
  background-color: #1c976b;
  color: white;
  text-decoration: none;
}

.campaign-button-wrap, .campaign-private-wrap {
  margin-bottom: 30px;
}

.campaign-description-wrap {
  margin-bottom: 30px;
}

#newborn_section2 {
  max-width: 1240px;
  margin: 0 auto;
}

#newborn_section2 {
  padding: 70px 0;
}

#newborn_section2 .content-box {
  margin-top: 30px;
}

@media (max-width:991px) {

}

@media (max-width:767px) {
  #newborn_section2 {
    padding: 50px 0;
  }
}

#newborn_section3, #newborn_section4 {
  padding: 70px 0;
}

@media (max-width:991px) {

}

@media (max-width:767px) {
  #newborn_section3, #newborn_section4 {
    padding: 50px 0;
  }
}

#newborn_section2 .content-box {
  max-width: 600px;
  text-align: center;
  margin: 30px auto;
}

#newborn_section2 .heading-box .sectionbigtext {
  margin-top: 25px;
}


#newborn_section2 .heading-box .sectionbigtext {
  font-family: 'OpenSans';
  font-size: 20px;
  font-weight: 300;
  line-height: 1.7;
  letter-spacing: 0;
  color: #42525a;
}

#newborn_section2 .content-box .sectionbigtext {
  font-family: 'OpenSans';
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  color: #42525a;
}

#newborn_section2 .sectiontitle {
  font-family: bely;
  font-size: 60px;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: normal;
  color: #1c976b;
}

@media (max-width: 767px) {
  #newborn_section2 .sectiontitle {
    font-size: 36px;
    color: #1c976b;
  }

  #newborn_section2 .heading-box .sectionbigtext {
    line-height: 1.1;
  }

  #newborn_section2 .content-box .sectionbigtext {
    margin-top: 30px;
  }
  
}

#newborn_section2 .heading-box {
  max-width: 940px;
  margin: 0 auto;
}

.form-field {
  margin-bottom: 20px;
}

.form-field .error-label,
.photo_wrap .error-label {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: red;
  margin-top: 5px;
  margin-bottom: 0;
}


.form-field input, 
.form-field select,
.form-field textarea {
  margin-bottom: 5px;
}

#newborn_section5 {
  padding-top: 60px;
  padding-bottom: 60px;
}
#newborn_section5 .sectiontitle {
  font-family: 'bely';
}

#newborn_section5 .sectionbigtext {
  font-size: 20px;
  font-weight: 300;
}

@media (max-width:767px) {
  .btn-meals, .btn-profile, .btn-share {
    display: flex;
    align-items: center;
    width: 300px;
    margin: 10px auto;
  }
}

#note_box {
  margin-top: 60px;
}

.note-wrap {
  max-width: 620px;
}

.note-wrap textarea {
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
  border: solid 1px #1b2125;
  padding: 10px 10px;
}

.image-box2 {
  position: relative;
}

.section-youtube {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);  
}


.section-youtube svg {
  font-size: 90px;
  color: #fed202;
  opacity: 0.64;
}

#section7 .sectiontitle,
#section8 .sectiontitle {
  font-family: Bely;
  font-size: 55px;
  letter-spacing: 0px;
  color: #42525a;
}

#section7 .sectionbigtext,
#section8 .sectionbigtext {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0px;
  color: #42525a;
}

@media (max-width: 767px) {
  #section7 .sectiontitle,
  #section8 .sectiontitle {    
    font-size: 48px;
    line-height: 55px;
  }
  
  #section7 .sectionbigtext,
  #section8 .sectionbigtext {
    font-size: 20px;
    line-height: 1.6;
  }  
}

@media (max-width: 991px) {
  #section8 .flexbox {
    flex-direction: column-reverse;
  }

  #section8 .image-box2 {
    margin-top: 30px;
  }
}

#section9 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  #section9 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

#section9 .sectionbigtext {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0px;
  text-align: center;
  color: #42525a;
}

#section9 .content-box {
  display: flex;
  justify-content: center;
}

.logo-item {
  margin-left: 25px;
  margin-right: 25px;
}

@media (max-width: 991px) {
  #section9 .content-box {
    display: block;
    text-align: center;
  }

  .logo-item {
    margin-bottom: 50px;
  }
}